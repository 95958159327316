:root {
  --primary: #1976d2;
  --body: #fafafa;
  --text-menu: #fff;
  --text-body: #4b4b4b;
  --card-background: #f2f2f2;
  --card-body-bg: white;
  --mais-noticias-bg: #fff;
  --box-shadow: #0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --theme: 0;
  --rem-size: 1.5rem;
  --secondary-bg: #e9ecef;
}

:root .dark-theme {
  --primary: #0d47a1;
  --body: #050505;
  --text-menu: #fff;
  --text-body: #fff;
  --card-background: #0d0d0d;
  --card-body-bg: #404040;
  --box-shadow: #bfbfbf;
  --mais-noticias-bg: #404040;
  --theme: 1;
  --link-color: #0d0d0d;
  --label-cor: #fff;
  --button-cor: #fff;
}
