// inicio temporario

:root {
  --bs-body-font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  .mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: gray !important;
  }
}
body {
  font-family: var(--bs-body-font-family);
  line-height: 1.5;
}
// fim do temporario

.nav-link {
  text-decoration: none;
}
.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 0;
}

//buttons
.btn-dark,
.botao {
  background: #212529 !important;
  color: #ffffff;
  border-radius: 8px;
  height: 37px;
  margin: 0px 20px 0px 0px;
  &:hover {
    background-color: #343a40;
  }
  &:active {
    background-color: #1d2124;
    color: white;
  }
  //buttons gray
}
.btn-gray {
  background: gray !important;
  color: #ffffff;
  border-radius: 8px;
  height: 37px;
  margin: 0px 20px 0px 0px;
  &:hover {
    background-color: #343a40;
  }
  &:active {
    background-color: #1d2124;
    color: white;
  }
  //h
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: 2.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

table {
  border-collapse: collapse;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

input,
button,
select,
optgroup,
textarea {
  font-size: inherit;
}

.relative-float-left {
  position: relative;
  float: left;
}
.form-label {
  margin-bottom: 5px;
}
.calendar-month {
  .calendar-button {
    position: absolute;
    top: 10px;
    right: 11px;
    width: 25px;
    height: 25px;
    background: #fff;
    pointer-events: none;
    button {
      border: none;
      background: transparent;
    }
  }
}
input[type="month"]:before,
input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: "Material Icons" !important;
  content: "calendar_month";
  width: 30px;
  height: 5px;
  position: absolute;
  top: 12px;
  right: 6px;
  color: #999;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="month"],
input[type="date"] {
  position: relative;
  padding: 5px;
  border: 3px solid lightgray;
  border-radius: 8px;
  color: gray;
}

input[type="month"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}
.text-danger {
  color: rgb(220, 53, 69) !important;
}
.frase-importante {
  color: #7c7a7a !important;
}
@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.fw-bold {
  font-weight: 700 !important;
}
label {
  display: inline-block;
}
