.p-toast .p-toast-message .p-toast-message-content {
  //   padding: 0.2rem 1rem !important;
}

span.p-toast-message-icon {
  color: inherit !important;
}

.p-datepicker-day-selected {
  background: black !important;
}

.p-datepicker-day-selected-range {
  background: black !important;
}

.p-select {
  width: 100%;
  border-radius: 8px !important;
  border: 3px solid lightgray !important;
  color: #7c7a7a;
  height: 38px;
}

.p-select:hover,
.p-select:focus {
  background-color: #e9ecef !important;
  border-color: lightgray !important;
}

.p-select-option.p-select-option-selected {
  background-color: #e9ecef !important;
  color: black !important;
}

.p-select .p-select-overlay {
  min-width: 75% !important;
  width: 75% !important;
}
