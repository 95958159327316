@use "theme";
@use "theme-style.scss";
@use "flexbox-styles";
@use "custom-material.scss";

$margin-size-geral: 10%;

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: "Inter", sans-serif !important;
  --mat-form-field-container-height: 5px;
}

body {
  background-color: var(--body) !important;
}
p {
  text-align: justify;
}
span:not(.p-component span):not(.mat-step-icon span),
a,
h1,
h2,
h3,
h4,
h5,
p:not(.p-component p) {
  color: var(--text-body);
}
.mat-drawer-container {
  background: none;
}

.mat-mdc-menu-content,
.mat-mdc-menu-panel.mat-mdc-menu-panel,
.mat-mdc-menu-submenu-icon,
.input-group,
thead,
tbody,
tfoot,
tr,
td,
th {
  filter: invert(var(--theme));
}
.flex-container-separador {
  display: flex;
  align-items: center;
}
@media (max-width: 900px) {
  .flex-container-separador {
    flex-direction: column;
    align-items: flex-start;
  }
}

.flex-spacer-separador {
  flex-grow: 1;
}
.send-files {
  background-color: #6c757d !important;
  color: white !important;
  margin: 8px;
  padding: 6px 12px;
}
.back-logo-brasao {
  filter: invert(0) !important;
}
.form-check-input[type="checkbox"] {
  border: 2px solid rgba(0, 0, 0, 0.5);
}
.report-container {
  background-color: transparent;
  padding-right: 30px;
  border-radius: 8px;
  display: flex;
  width: 500px;
  justify-content: end;
  align-items: center;
  gap: 30px;

  .report-button {
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .material-icons {
    color: white !important;
    margin-left: 10px;
    background-color: #000 !important;
  }

  .material-icons-custom {
    color: white !important;
    margin-left: 10px;
    background-color: #000 !important;
  }
}
.mat-mdc-snack-bar-container {
  &.mdc-snackbar__surface {
    min-height: 55px;
  }
  &.snack-bar-success {
    --mdc-snackbar-container-color: #51a351;
  }
  &.snack-bar-warning {
    --mdc-snackbar-container-color: #f89406;
  }
  &.snack-bar-error {
    --mdc-snackbar-container-color: #bd362f;
  }
  &.snack-bar-info {
    --mdc-snackbar-container-color: #2f96b4;
  }
}
.output-field {
  min-height: 46px;
  background-color: var(--secondary-bg);
  border-radius: 8px;
  border: 3px solid lightgray;
  padding: 8px 15px;
}

li,
ul {
  list-style-type: none;
}
#limpaForm {
  span,
  .mdc-button__label {
    color: gray !important;
    text-decoration: underline;
  }
}
.estilo-month-picker .mat-calendar-period-button {
  pointer-events: none;
}
.estilo-month-picker .mat-calendar-arrow {
  display: none;
}

.form-flex {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.full-width {
  width: 100%;
}

.p-inputnumber {
  border: 3px solid lightgray;
  border-radius: 10px;
  color: #7c7a7a;
  height: 38px;
}

.p-inputnumber .p-inputtext:hover,
p-inputnumber .p-inputtext:focus {
  background-color: #e9ecef !important;
  border-color: lightgray !important;
}

.no-border {
  border: 0px;
}
