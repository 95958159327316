//material forms
.mat-full-width {
  width: 100%;
}
.mat-mdc-form-field,
.calendar-month {
  width: 100%;
  height: inherit;
}
.mdc-line-ripple {
  display: none;
}
.mat-disabled {
  display: flex;
  align-items: flex-end;
  color: grey;
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
}
.mdc-text-field--disabled.mdc-text-field--filled,
.mat-mdc-text-field-wrapper {
  margin: 0;
  padding: 0 10px;
}
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-paginator-page-size-select {
  border: none;
  background-color: white;
  border-radius: 0;
}
.mat-mdc-form-field-infix {
  min-height: 20px;
  max-height: 25px;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
  color: grey;
}
.mat-mdc-form-field-focus-overlay {
  background: none;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.mat-mdc-form-field-flex {
  display: flex;
  align-items: center !important;
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #e9ecef;
}
.mdc-text-field {
  border-radius: 8px !important;
  border: 3px solid lightgray;
}

.mdc-button__label {
  color: white !important;
}
.mat-disabled .mat-mdc-text-field-wrapper {
  background-color: var(--secondary-bg);
}
.mat-mdc-icon-button.mat-mdc-button-base {
  height: 30px;
  padding-top: 3px;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  background-color: lightgray !important;
}
